import {
  BI_ORIGINS,
  ChangeRsvpModalMode,
  getEventId,
  getEventTitle,
  isFull,
  isNoResponseEnabled,
  isRsvp,
  isTicketed,
  MembersModal,
} from '@wix/wix-events-commons-statics'
import {RsvpStatus} from '@wix/events-types'
import _ from 'lodash'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {RegistrationErrorKey} from '../../../../commons/enums'
import {isEditor, isMobile} from '../../../../commons/selectors/environment'
import {openDialogModal} from '../../../../commons/services/modal'
import {getCurrentMemberDetails, getCurrentMemberId} from '../selectors/current-member-details'
import {isDemoEvent} from '../selectors/event'
import {
  getMemberEventRsvpStatus,
  getMemberRsvpId,
  isMemberAbleToFinishRsvp,
  isMemberRsvpExists,
} from '../selectors/member-rsvp'
import {inRsvpForm} from '../selectors/navigation'
import {shouldNavigateToForm, isStepCompleted, getCurrentStep, getRsvpDetails, isStepVisible} from '../selectors/rsvp'
import {GetState, RegFormData, RsvpError, State, ThankYouMessageState} from '../types'
import {extractFormData} from '../utils/api-data-mapper'
import {isDemoMode} from '../../../../commons/selectors/instance'
import {FormStep, RSVP_STEPS} from '../constants/constants'
import {hasAgreedWithPolicies, hasPolicies, getAgreementToken} from '../selectors/policies'
import {isRequestPending} from '../selectors/pending-requests'
import {getEvent, getMembers} from './event'
import {ensureLoginForMembersOnly, promptLogin} from './members'
import {navigateToChangeRsvp, navigateToForm, navigateToThankYouMessage, navigateToTicketsPicker} from './navigation'
import {registrationButtonClicked} from './registration'
import {getPolicies} from './policies'

export const RESET_RSVP_ERROR = 'RESET_RSVP_ERROR'
export const SEND_RSVP = createActions('SEND_RSVP')
export const UPDATE_RSVP = createActions('UPDATE_RSVP')
export const UPDATE_RSVP_STATUS = createActions('UPDATE_RSVP_STATUS')
export const DELETE_RSVP = createActions('DELETE_RSVP')
export const GET_MEMBER_RSVP = createActions('GET_MEMBER_RSVP')
export const SKIP_GET_MEMBER_EVENT_RSVP = 'SKIP_GET_MEMBER_EVENT_RSVP'
export const EDIT_RSVP_STEP = 'EDIT_RSVP_STEP'
export const SET_RSVP_DETAILS = 'SET_RSVP_DETAILS'
export const NEXT_RSVP_STEP = 'NEXT_RSVP_STEP'
export const CHANGE_RESPONSE = 'CHANGE_RESPONSE'

export const getMemberRsvp = () => async (dispatch: Function, getState: GetState) => {
  const state = getState()
  if (state.membersAreaEnabled) {
    const currentMemberId = getCurrentMemberId(state)
    if (currentMemberId && !isDemoEvent(state) && isRsvp(state.event)) {
      await dispatch(callAPI(GET_MEMBER_RSVP, getEventId(state.event), currentMemberId))
      if (isMemberRsvpExists(getState()) && inRsvpForm(getState())) {
        dispatch(navigateToChangeRsvp())
      }
      return
    }
  }
  return dispatch({type: SKIP_GET_MEMBER_EVENT_RSVP})
}

export const resetRsvpError = () => ({type: RESET_RSVP_ERROR})

export const sendRsvp = (eventId: string, guest: RegFormData) => async (dispatch: Function, getState: GetState) => {
  const state = getState()

  if (isRequestPending(state, SEND_RSVP.REQUEST)) {
    return
  }

  const policyAgreementToken = getAgreementToken(state)
  const guestResponse = guest.response

  if (isDemoEvent(state) || isDemoMode(state.instance)) {
    let thankYouMessageState: ThankYouMessageState = guestResponse === RsvpStatus.YES ? 'yes' : 'no'

    if (guestResponse === RsvpStatus.WAITING) {
      thankYouMessageState = 'waiting'
    }

    return dispatch(navigateToThankYouMessage(thankYouMessageState))
  }

  const promise = dispatch(
    callAPI(
      SEND_RSVP,
      eventId,
      extractFormData(guest),
      guestResponse,
      getCurrentMemberDetails(state)?.id,
      policyAgreementToken,
    ),
  )

  promise.catch((e: RsvpError) => {
    if (e.response?.data?.metadata?.member_already_registered) {
      dispatch(promptLogin())
    } else if (e.response?.data?.metadata?.error_key === RegistrationErrorKey.INVALID_POLICY_AGREEMENT_TOKEN) {
      dispatch(handleInvalidPolicy())
    } else {
      throw e
    }
  })

  const response = await promise

  if (!_.isEmpty(response.rsvp)) {
    dispatch(navigateToThankYouMessage(RsvpStatus[guest.response].toLowerCase() as ThankYouMessageState))
  }

  return response
}

const handleInvalidPolicy = () => async (dispatch: Function) => {
  await dispatch(getPolicies({showAlert: true}))
  dispatch(editRsvpStep(FormStep.Policies))
}

export const updateRsvp = (eventId: string, guest: RegFormData) => async (dispatch: Function, getState: GetState) => {
  const state = getState()

  if (isDemoEvent(state) || isDemoMode(state.instance)) {
    return dispatch(navigateToThankYouMessage('yes'))
  }

  const response = await dispatch(
    callAPI(UPDATE_RSVP, eventId, extractFormData(guest), guest.response, getMemberRsvpId(state)),
  )

  if (!_.isEmpty(response.rsvp)) {
    dispatch(navigateToThankYouMessage(RsvpStatus[guest.response].toLowerCase() as ThankYouMessageState))
  }

  return response
}

export const updateRsvpStatus = (eventId: string, rsvpId: string, status: RsvpStatus) =>
  callAPI(UPDATE_RSVP_STATUS, eventId, rsvpId, status)

export const deleteRsvp = (eventId: string, rsvpId: string) => callAPI(DELETE_RSVP, eventId, rsvpId)

export const updateRsvpOrNavigateToForm = (state: State, dispatch: Function, resolve: Function) => {
  const {event} = state
  const eventId = getEventId(event)
  const rsvpId = getMemberRsvpId(state)

  if (shouldNavigateToForm(state)) {
    dispatch(navigateToForm())
    return resolve(true)
  }

  dispatch(updateRsvpStatus(eventId, rsvpId, RsvpStatus.YES))
}

export const changeMemberRsvp =
  (returnFocusElement: string) =>
  (dispatch: Function, getState: GetState): Promise<boolean> => {
    return new Promise(resolve => {
      const state = getState()
      const {event} = state
      const eventTitle = getEventTitle(event)
      const eventId = getEventId(event)
      const params = {event: eventTitle, eventId, origin: BI_ORIGINS.EVENT_DETAILS}

      if (isEditor(state)) {
        return dispatch(
          openDialogModal({
            type: MembersModal.NO_CANCEL_RSVP,
            params,
            onClose: () => resolve(false),
            returnFocusElement,
          }),
        )
      }

      const noEnabled = isNoResponseEnabled(event)
      const status = getMemberEventRsvpStatus(state)
      const mode = getChangeRsvpModalMode(state)

      return dispatch(
        openDialogModal({
          type: MembersModal.UPDATE_RSVP,
          params: {...params, mode},
          onClose: () => resolve(false),
          returnFocusElement,
          onConfirm: async () => {
            const rsvpId = getMemberRsvpId(state)

            if (!isMemberRsvpExists(state)) {
              dispatch(navigateToForm())
              return resolve(true)
            }

            if (noEnabled) {
              if ([RsvpStatus.YES, RsvpStatus.WAITING].includes(status)) {
                await dispatch(updateRsvpStatus(eventId, rsvpId, RsvpStatus.NO))
              } else if (getMemberEventRsvpStatus(state) === RsvpStatus.NO) {
                await (isFull(event)
                  ? dispatch(updateRsvpStatus(eventId, rsvpId, RsvpStatus.WAITING))
                  : updateRsvpOrNavigateToForm(state, dispatch, resolve))
              }
            } else {
              await dispatch(deleteRsvp(eventId, rsvpId))
            }

            resolve(true)
            await dispatch(getEvent())
            dispatch(getMembers())
          },
        }),
      )
    })
  }

const getChangeRsvpModalMode = (state: State) => {
  const {event} = state
  const noEnabled = isNoResponseEnabled(event)
  const status = getMemberEventRsvpStatus(state)

  if (!isMemberRsvpExists(state)) {
    return ChangeRsvpModalMode.UPDATE_TO_YES
  }

  if (!noEnabled) {
    return ChangeRsvpModalMode.DELETE
  }

  if (isFull(event) && status === RsvpStatus.NO) {
    return ChangeRsvpModalMode.WAITLIST
  }

  if (status === RsvpStatus.NO) {
    return ChangeRsvpModalMode.UPDATE_TO_YES
  }

  return ChangeRsvpModalMode.UPDATE_TO_NO
}

export const handleRSVP = () => async (dispatch: Function, getState: GetState) => {
  const state = getState()

  dispatch(registrationButtonClicked())

  if (await dispatch(ensureLoginForMembersOnly())) {
    if (isMemberRsvpExists(getState())) {
      if (isMemberAbleToFinishRsvp(getState())) {
        return dispatch(navigateToForm())
      } else {
        return
      }
    }
    if (isMobile(state) && isTicketed(state.event)) {
      return dispatch(navigateToTicketsPicker())
    }

    dispatch(navigateToForm())
  }
}

const setRsvpDetails = (rsvpDetails: RegFormData) => ({
  type: SET_RSVP_DETAILS,
  payload: {rsvpDetails},
})

const rsvp = (data?: any) => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const rsvpDetails = data || getRsvpDetails(state)
  const eventId = getEventId(state.event)

  if (isMemberRsvpExists(state) && shouldNavigateToForm(state)) {
    dispatch(updateRsvp(eventId, rsvpDetails))
  } else {
    dispatch(sendRsvp(eventId, rsvpDetails))
  }
}

export const submitRsvpStep = (data: any) => async (dispatch: Function, getState: GetState) => {
  const state = getState()
  const currentStep = getCurrentStep(state)

  switch (currentStep) {
    case FormStep.BuyerDetails: {
      if (hasPolicies(state)) {
        dispatch(setRsvpDetails(data))
      } else {
        dispatch(rsvp(data))
      }
      break
    }
    case FormStep.Policies: {
      await dispatch(rsvp())
      break
    }
    default:
      break
  }

  dispatch(handleNextRsvpStep())
}

export const editRsvpStep = (currentStep: FormStep) => ({
  type: EDIT_RSVP_STEP,
  payload: {
    currentStep,
  },
})

export const handleNextRsvpStep = () => (dispatch: Function, getState: GetState) => {
  const state = getState()

  if (
    isStepCompleted({
      step: getCurrentStep(state),
      rsvpDetails: getRsvpDetails(state),
      agreedWithPolicies: hasAgreedWithPolicies(state),
    })
  ) {
    dispatch(nextStep())
  }
}

const nextStep = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const {rsvpDetails} = state.rsvp
  const next = RSVP_STEPS.find(step => {
    const isVisible = isStepVisible({step, hasPolicies: hasPolicies(state)})
    const isCompleted = isStepCompleted({
      step,
      rsvpDetails,
      agreedWithPolicies: hasAgreedWithPolicies(state),
    })
    return isVisible && !isCompleted
  })
  const last = RSVP_STEPS[RSVP_STEPS.length - 1]

  dispatch({
    type: NEXT_RSVP_STEP,
    payload: {
      nextStep: next ?? last,
    },
  })
}

export const changeResponse = (response: wix.events.rsvp.RsvpStatus) => ({
  type: CHANGE_RESPONSE,
  payload: {response},
})
