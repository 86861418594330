import {AnyAction} from 'redux'
import {CHANGE_EVENT} from '../actions/event'
import {PoliciesState} from '../types'
import {TOGGLE_AGREE_POLICIES, GET_POLICIES} from '../actions/policies'
import {SEND_RSVP} from '../actions/rsvp'

const defaultState: PoliciesState = {
  agreed: false,
  showAlert: false,
  policies: [],
  policyAgreementToken: null,
}

export const policies = (state: PoliciesState = defaultState, action: AnyAction) => {
  switch (action.type) {
    case CHANGE_EVENT:
      return {
        ...defaultState,
        ...action.payload.policies,
      }
    case TOGGLE_AGREE_POLICIES:
      return {
        ...state,
        agreed: !state.agreed,
      }
    case GET_POLICIES.REQUEST:
      return {
        ...state,
        showAlert: action.args[1].showAlert,
        agreed: false,
      }
    case GET_POLICIES.SUCCESS:
      return {
        ...state,
        ...action.payload,
        agreed: false,
        showAlert: action.args[1].showAlert,
      }
    case SEND_RSVP.FAILURE:
      return {
        ...state,
        agreed: false,
      }
    default:
      return state
  }
}
