import {isDraft, hasSeatingPlan} from '@wix/wix-events-commons-statics'
import {createAction} from '@reduxjs/toolkit'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {isTemplate} from '../../../../commons/selectors/instance'
import {isRequestPending} from '../selectors/pending-requests'
import {GetState, StoreExtraArgs} from '../types'
import {isUpgradeNeededForCheckout} from '../selectors/checkout-options'
import {isMobile} from '../../../../commons/selectors/environment'
import {UserRole} from '../constants/constants'
import {Interaction} from '../utils/interactions'
import {getReservationQuantities} from '../selectors/selected-tickets'
import {getSeatsReservationQuantities} from '../selectors/seating/places'
import {openCheckoutUnavailable, openUpgradeToPremium} from './modals'
import {NAVIGATE, navigateToMobileCheckout, navigateToTicketForm} from './navigation'

export const RESERVE_TICKETS = createActions('RESERVE_TICKETS')
export const CANCEL_RESERVATION = createActions('CANCEL_RESERVATION')

export const reserveTickets =
  (eventId: string) =>
  (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    const state = getState()
    const upgradeNeeded = isUpgradeNeededForCheckout(state)
    const owner = wixCodeApi.user.currentUser.role === UserRole.ADMIN

    if (isTemplate(state) || isDraft(state.event)) {
      return isMobile(state) ? dispatch(navigateToMobileCheckout()) : dispatch(navigateToTicketForm())
    }

    if (upgradeNeeded && (isMobile(state) || !owner)) {
      return dispatch(openCheckoutUnavailable())
    }

    const ticketQuantities: wix.events.ticketing.TicketReservationQuantity[] = hasSeatingPlan(state.event)
      ? getSeatsReservationQuantities(state)
      : getReservationQuantities(state)

    if (upgradeNeeded && owner) {
      return dispatch(openUpgradeToPremium({eventId, ticketQuantities}))
    }

    return dispatch(makeReservation(eventId, ticketQuantities))
  }

export const cancelReservation = (eventId: string, reservationId: string) => (dispatch: Function) => {
  if (reservationId) {
    dispatch(callAPI(CANCEL_RESERVATION, eventId, reservationId))
  }
}

export const makeReservation =
  (eventId: string, ticketQuantities: wix.events.ticketing.TicketReservationQuantity[]) =>
  async (dispatch: Function, getState: GetState, {flowAPI}: StoreExtraArgs) => {
    const state = getState()

    if (!isRequestPending(state, NAVIGATE.REQUEST) && !isRequestPending(state, RESERVE_TICKETS.REQUEST)) {
      flowAPI.fedops.interactionStarted(Interaction.CreateReservation)
      await dispatch(callAPI(RESERVE_TICKETS, eventId, ticketQuantities))
      isMobile(state) ? dispatch(navigateToMobileCheckout()) : dispatch(navigateToTicketForm())
    }
  }

export const clearReservationError = createAction<boolean>('CLEAR_RESERVATION_ERROR')
